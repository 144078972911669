.post-heading {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.delete-btn {
  margin-top: 4rem;
  margin: 3rem;
  text-align: center;
  padding: 3rem;
}
.profile-heading {
  margin-top: 35px;
  margin-left: 30px;
}

.post-text {
  margin: 30px;
  font-size: 7rem;
}
.post-imports-icon {
  display: flex;
  justify-content: space-between;
}
.image-box {
  width: 550px;
  min-height: 350px;
  margin: auto;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #999999;
  color: #fff;
  font-size: 2rem;
}

.cross-position {
  position: relative;
  left: 420px;
  top: -150px;
}
.choose-btn {
  position: relative;
  right: 30px;
  top: 120px;
}

/* For responsiveness, you can adjust the width and height as needed */

.comment-dropDown {
  z-index: 200;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 8rem;
  padding: 1rem 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.comment-dropDown > a {
  text-decoration: none;
  padding: 0.5rem;
}

.handle-remove {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.add_photo_btn {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  color: black;
  width: 120px;
  z-index: 200;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
}

.Img_Box_Container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Img_Box_addPhoto_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 2560px) {
  /* .image-box {
    width: 80%;
    max-width: 350px;
    height: 300px;
  } */
  .cross-position {
    position: relative;
    left: 480px;
    top: -150px;
  }
  .choose-btn {
    position: relative;
    right: 50px;
    top: 130px;
  }
  .create_one_img_post {
    width: 360px;
    height: 200px;
  }
  .create_post_withtwoimg_img1 {
    width: 280px;
    height: 240px;
    margin: 2rem;
  }
  .create_post_withtwoimg_img2 {
    width: 230px;
    height: 240px;
    margin: 0 0 0 1rem;
  }

  .create_post_withtwoimg {
    width: 130px;
    height: 140px;
    margin: 0.2rem !important;
  }
  .create_three_img_post_imgone {
    width: 200px;
    height: 165px;
  }
  .create_three_img_post_imgtwo {
    width: 100px;
    height: 80px;
    margin-bottom: 0.2rem;
  }
  .create_three_img_post_imgthree {
    width: 100px;
    height: 80px;
  }
}
@media (max-width: 1440px) {
  /* .image-box {
    width: 80%;
    max-width: 350px;
    height: 300px;
  } */
  .cross-position {
    position: relative;
    left: 480px;
    top: -150px;
  }
  .choose-btn {
    position: relative;
    right: 50px;
    top: 130px;
  }
  .create_one_img_post {
    width: 360px;
    height: 200px;
  }
  .create_post_withtwoimg_img1 {
    width: 280px;
    height: 240px;
    margin: 2rem;
  }
  .create_post_withtwoimg_img2 {
    width: 230px;
    height: 240px;
    margin: 0 0 0 1rem;
  }

  .create_post_withtwoimg {
    width: 130px;
    height: 140px;
    margin: 0.2rem !important;
  }
  .create_three_img_post_imgone {
    width: 300px;
    height: 165px;
  }
  .create_three_img_post_imgtwo {
    width: 100px;
    height: 80px;
    margin-bottom: 0.2rem;
  }
  .create_three_img_post_imgthree {
    width: 100px;
    height: 80px;
  }
}

@media (max-width: 768px) {
  /* .image-box {
    width: 80%;
    max-width: 350px;
    height: 300px;
  } */
  .cross-position {
    position: relative;
    left: 300px;
    top: -130px;
  }
  .choose-btn {
    position: relative;
    right: 110px;
    top: 140px;
  }
  .create_one_img_post {
    width: 260px;
    height: 200px;
  }
  .create_post_withtwoimg {
    width: 130px;
    height: 140px;
    margin: 0.2rem !important;
  }

  .create_post_withtwoimg_img1 {
    width: 90%;
    height: 200px;
    margin: 0.2rem !important;
  }
  .create_post_withtwoimg_img2 {
    width: 90%;
    height: 200px;
    margin: 0.2rem !important;
  }
  .create_three_img_post_imgone {
    width: 100px;
    height: 165px;
  }
  .create_three_img_post_imgtwo {
    width: 100px;
    height: 80px;
    margin-bottom: 0.2rem;
  }
  .create_three_img_post_imgthree {
    width: 100px;
    height: 80px;
  }
}

@media (max-width: 425px) {
  .cross-position {
    position: relative;
    left: 199px;
    top: -110px;
  }
  .choose-btn {
    position: relative;
    left: -32px;
    top: 100px;
  }

  .create_one_img_post {
    width: 130px;
    height: 170px;
    margin-top: 2.5rem;
  }
  .create_post_withtwoimg_img1 {
    width: 90%;
    height: 100px;
    margin: 0.2rem !important;
  }
  .create_post_withtwoimg_img2 {
    width: 90%;
    height: 100px;
    margin: 0.2rem !important;
  }
  .create_three_img_post_imgone {
    width: 50%;
    height: 115px;
  }
  .create_three_img_post_imgtwo {
    width: 100px;
    height: 80px;
    margin-bottom: 0.2rem;
  }
  .create_three_img_post_imgthree {
    width: 100px;
    height: 80px;
  }
}

@media (max-width: 576px) {
  .image-box {
    width: 90%;
    max-width: 250px;
    min-height: 250px;
  }
}

.photo-container {
  height: 200px;
  width: 250px;
}
