/*
 *  Document   : themes.css
 *  Author     : pixelcave
 *  Description: The themes stylesheet of the template. The classes can be used to
 *  alter the text, the background or the border color of an element individually
 *
 */

/*
=================================================================
THEME DEFAULT
=================================================================
*/

.themed-color-default {
    color: #1bbae1 !important;
}

.themed-border-default {
    border-color: #1bbae1 !important;
}

.themed-background-default {
    background-color: #1bbae1 !important;
}

.themed-color-dark-default {
    color: #394263 !important;
}

.themed-border-dark-default {
    border-color: #394263 !important;
}

.themed-background-dark-default {
    background-color: #00092D !important;
}

/*
=================================================================
THEME NIGHT
=================================================================
*/

.themed-color-night {
    color: #888888 !important;
}

.themed-border-night {
    border-color: #888888 !important;
}

.themed-background-night {
    background-color: #888888 !important;
}

.themed-color-dark-night {
    color: #333333 !important;
}

.themed-border-dark-night {
    border-color: #333333 !important;
}

.themed-background-dark-night {
    background-color: #333333 !important;
}

/*
=================================================================
THEME AMETHYST
=================================================================
*/

.themed-color-amethyst {
    color: #af64cc !important;
}

.themed-border-amethyst {
    border-color: #af64cc !important;
}

.themed-background-amethyst {
    background-color: #af64cc !important;
}

.themed-color-dark-amethyst {
    color: #583a63 !important;
}

.themed-border-dark-amethyst {
    border-color: #583a63 !important;
}

.themed-background-dark-amethyst {
    background-color: #583a63 !important;
}

/*
=================================================================
THEME MODERN
=================================================================
*/

.themed-color-modern {
    color: #46b7bf !important;
}

.themed-border-modern {
    border-color: #46b7bf !important;
}

.themed-background-modern {
    background-color: #46b7bf !important;
}

.themed-color-dark-modern {
    color: #3b3f40 !important;
}

.themed-border-dark-modern {
    border-color: #3b3f40 !important;
}

.themed-background-dark-modern {
    background-color: #3b3f40 !important;
}

/*
=================================================================
THEME AUTUMN
=================================================================
*/

.themed-color-autumn {
    color: #e67e22 !important;
}

.themed-border-autumn {
    border-color: #e67e22 !important;
}

.themed-background-autumn {
    background-color: #e67e22 !important;
}

.themed-color-dark-autumn {
    color: #4a392b !important;
}

.themed-border-dark-autumn {
    border-color: #4a392b !important;
}

.themed-background-dark-autumn {
    background-color: #4a392b !important;
}

/*
=================================================================
THEME FLATIE
=================================================================
*/

.themed-color-flatie {
    color: #1ec1b8 !important;
}

.themed-border-flatie {
    border-color: #1ec1b8 !important;
}

.themed-background-flatie {
    background-color: #1ec1b8 !important;
}

.themed-color-dark-flatie {
    color: #32323a !important;
}

.themed-border-dark-flatie {
    border-color: #32323a !important;
}

.themed-background-dark-flatie {
    background-color: #32323a !important;
}

/*
=================================================================
THEME SPRING
=================================================================
*/

.themed-color-spring {
    color: #27ae60 !important;
}

.themed-border-spring {
    border-color: #27ae60 !important;
}

.themed-background-spring {
    background-color: #27ae60 !important;
}

.themed-color-dark-spring {
    color: #344a3d !important;
}

.themed-border-dark-spring {
    border-color: #344a3d !important;
}

.themed-background-dark-spring {
    background-color: #344a3d !important;
}

/*
=================================================================
THEME FANCY
=================================================================
*/

.themed-color-fancy {
    color: #d9416c !important;
}

.themed-border-fancy {
    border-color: #d9416c !important;
}

.themed-background-fancy {
    background-color: #d9416c !important;
}

.themed-color-dark-fancy {
    color: #352b4e !important;
}

.themed-border-dark-fancy {
    border-color: #352b4e !important;
}

.themed-background-dark-fancy {
    background-color: #352b4e !important;
}

/*
=================================================================
THEME FIRE
=================================================================
*/

.themed-color-fire {
    color: #e74c3c !important;
}

.themed-border-fire {
    border-color: #e74c3c !important;
}

.themed-background-fire {
    background-color: #e74c3c !important;
}

.themed-color-dark-fire {
    color: #4a2e2b !important;
}

.themed-border-dark-fire {
    border-color: #4a2e2b !important;
}

.themed-background-dark-fire {
    background-color: #4a2e2b !important;
}

/*
=================================================================
THEME CORAL
=================================================================
*/

.themed-color-coral {
    color: #ff6b6b !important;
}

.themed-border-coral {
    border-color: #ff6b6b !important;
}

.themed-background-coral {
    background-color: #ff6b6b !important;
}

.themed-color-dark-coral {
    color: #3c3e4f !important;
}

.themed-border-dark-coral {
    border-color: #3c3e4f !important;
}

.themed-background-dark-coral {
    background-color: #3c3e4f !important;
}

/*
=================================================================
THEME LAKE
=================================================================
*/

.themed-color-lake {
    color: #e38e7b !important;
}

.themed-border-lake {
    border-color: #e38e7b !important;
}

.themed-background-lake {
    background-color: #e38e7b !important;
}

.themed-color-dark-lake {
    color: #043e50 !important;
}

.themed-border-dark-lake {
    border-color: #043e50 !important;
}

.themed-background-dark-lake {
    background-color: #043e50 !important;
}

/*
=================================================================
THEME FOREST
=================================================================
*/

.themed-color-forest {
    color: #0ead87 !important;
}

.themed-border-forest {
    border-color: #0ead87 !important;
}

.themed-background-forest {
    background-color: #0ead87 !important;
}

.themed-color-dark-forest {
    color: #3b322c !important;
}

.themed-border-dark-forest {
    border-color: #3b322c !important;
}

.themed-background-dark-forest {
    background-color: #3b322c !important;
}

/*
=================================================================
THEME WATERLILY
=================================================================
*/

.themed-color-waterlily {
    color: #4ca5a9 !important;
}

.themed-border-waterlily {
    border-color: #4ca5a9 !important;
}

.themed-background-waterlily {
    background-color: #4ca5a9 !important;
}

.themed-color-dark-waterlily {
    color: #4f243e !important;
}

.themed-border-dark-waterlily {
    border-color: #4f243e !important;
}

.themed-background-dark-waterlily {
    background-color: #4f243e !important;
}

/*
=================================================================
THEME EMERALD
=================================================================
*/

.themed-color-emerald {
    color: #69b899 !important;
}

.themed-border-emerald {
    border-color: #69b899 !important;
}

.themed-background-emerald {
    background-color: #69b899 !important;
}

.themed-color-dark-emerald {
    color: #07313a !important;
}

.themed-border-dark-emerald {
    border-color: #07313a !important;
}

.themed-background-dark-emerald {
    background-color: #07313a !important;
}


/*
=================================================================
THEME BLACKBERRY
=================================================================
*/

.themed-color-blackberry {
    color: #148e81 !important;
}

.themed-border-blackberry {
    border-color: #148e81 !important;
}

.themed-background-blackberry {
    background-color: #148e81 !important;
}

.themed-color-dark-blackberry {
    color: #352738 !important;
}

.themed-border-dark-blackberry {
    border-color: #352738 !important;
}

.themed-background-dark-blackberry {
    background-color: #352738 !important;
}